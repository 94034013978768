/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Si el reglamento para la inteligencia artificial sale tal y como está, la Unión Europea perderá el tren de la tecnología también esta década."), "\n", React.createElement(_components.p, null, "El borrador de la ", React.createElement(_components.a, {
    href: "https://www.europarl.europa.eu/meetdocs/2014_2019/plmrep/COMMITTEES/CJ40/DV/2023/05-11/ConsolidatedCA_IMCOLIBE_AI_ACT_EN.pdf"
  }, "Ley IA"), " que está discutiendo el parlamento europeo se ha visto envuelto en polémica por el ataque que en un principio parece hacer a los modelos open source. Como explica ", React.createElement(_components.a, {
    href: "https://openfuture.eu/blog/undermining-the-foundation-of-open-source-ai/"
  }, "Paul Keller en Open Future"), ", esto no es así, ya que entraría dentro de las excepciones. Sin embargo, del mismo artículo se puede extraer que otra suerte tendrían las startups europeas que decidan emprender en inteligencia artificial. En él podemos leer:\n«En conjunto, estas obligaciones suponen una carga de cumplimiento bastante importante para los proveedores de sistemas de IA fundacional que será muy difícil de cumplir para todos, salvo para los más grandes.»"), "\n", React.createElement(_components.p, null, "Recordemos que en Europa no existen grandes tecnológicas como Microsoft o Google que puedan amparar nuevos proyectos de este tipo, lo que nos lleva a la conclusión de que en Europa no se podrá formar un gigante de la IA al estilo de OpenAI, las burocracia lo mataría antes de llegar a desplegar ningún servicio."), "\n", React.createElement(_components.p, null, "Además esta regulación parece bastante alineada con ", React.createElement(_components.a, {
    href: "https://www.nasdaq.com/articles/openai-chief-goes-before-us-congress-to-propose-licenses-for-building-ai"
  }, "las reclamaciones de OpenAI de licenciar el desarrollo de IA"), ", lo que unido a las ", React.createElement(_components.a, {
    href: "https://www.semianalysis.com/p/google-we-have-no-moat-and-neither"
  }, "filtraciones de Google donde considera a la IA open source su principal competidor"), " se hace ligeramente sospechoso."), "\n", React.createElement(_components.p, null, "La Unión Europea es un referente en cuanto a legislación que protege los derechos de la ciudadanía, un buen ejemplo de esto, aún con sus peros, es el RGPD. Pero no podemos sacrificar la innovación cambio de la protección de derechos, debe haber un punto de equilibrio."), "\n", React.createElement(_components.p, null, "La inteligencia artificial puede ser la innovación más grande que tengamos en esta década. Europa ya perdió el carro de la revolución del smartphone la década pasada y esta década parece que va de cabeza a perder también el de la IA."), "\n", React.createElement(_components.p, null, "Foto de ", React.createElement("a", {
    href: "https://unsplash.com/@alexandrelallemand?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
  }, "ALEXANDRE LALLEMAND"), " en ", React.createElement("a", {
    href: "https://unsplash.com/es/fotos/Pcs3mOL14Sk?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
  }, "Unsplash")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
